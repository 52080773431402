<template>
  <section class="contact-section" id="contacts">
    <div class="container">
      <div class="section-title">
        <h2>Contact <span>Us</span></h2>
      </div>
      <div class="contact-info">
        <div class="address">
          <h3>Address</h3>
          <p>{{ contactUs.address }}</p>
          <h3>Email</h3>
          <p><a :href="'mailto:' + contactUs.email">{{ contactUs.email }}</a></p>
          <h3>Office Hours</h3>
          <p>{{ contactUs.monday }}</p>
          <p>{{ contactUs.saturday }}</p>
          <p>{{ contactUs.sunday }}</p>
        </div>
        <div class="map-container">
          <!-- Google Map iframe -->
          <iframe
            v-if="contactUs.address"
            :src="`https://www.google.com/maps?q=${encodeURIComponent(contactUs.address)}&output=embed`"
            width="600"
            height="450"
            style="border:0;"
            allowfullscreen
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <div class="contact-form">
        <h3>Leave a Message</h3>
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="name">Name</label>
            <input 
              type="text" 
              id="name" 
              v-model="form.name" 
              @blur="validateName" 
              :class="{ 'is-invalid': errors.name }"
              required 
            />
            <div v-if="errors.name" class="error-message">{{ errors.name }}</div>
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <input 
              type="email" 
              id="email" 
              v-model="form.email" 
              @blur="validateEmail" 
              :class="{ 'is-invalid': errors.email }"
              required 
            />
            <div v-if="errors.email" class="error-message">{{ errors.email }}</div>
          </div>

          <div class="form-group">
            <label for="phone">Phone</label>
            <input 
              type="tel" 
              id="phone" 
              v-model="form.phone" 
              @blur="validatePhone" 
              :class="{ 'is-invalid': errors.phone }"
              required 
            />
            <div v-if="errors.phone" class="error-message">{{ errors.phone }}</div>
          </div>

          <div class="form-group">
            <label for="case">Message</label>
            <textarea 
              id="case" 
              v-model="form.case" 
              @blur="validateCase" 
              :class="{ 'is-invalid': errors.case }"
              rows="4" 
              required
            ></textarea>
            <div v-if="errors.case" class="error-message">{{ errors.case }}</div>
          </div>

          <div class="tab-button">
            <button type="submit" class="cta">Send Message</button>
          </div>

          <!-- Notification messages -->
          <div v-if="successMessage" class="notification success">{{ successMessage }}</div>
          <div v-if="errorMessage" class="notification error">{{ errorMessage }}</div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: "ContactSection",
  data() {
    return {
      contactUs: {
        address: "",
        email: "",
        monday: "",
        saturday: "",
        sunday: "",
      },
      form: {
        name: "",
        email: "",
        phone: "",
        case: "",
      },
      errors: {},
      successMessage: "",
      errorMessage: "",
    };
  },
  created() {
    this.fetchContactData();
  },
  methods: {
    fetchContactData() {
      // Статичні дані замість виклику API
      this.contactUs = {
        address: "Rue Breydel 34-36, 1040 Brussels",
        email: "info@recoveryassist.com",
        monday: "Monday to Friday: 9:00 am to 6:00 pm",
        saturday: "Saturday: 9:00 am to 12 noon",
        sunday: "Closed on Sundays",
      };
    },
    submitForm() {
      this.errors = {}; // Очищення попередніх помилок

      if (this.validateForm()) {
        // Отримання інформації про браузер і платформу
        const userAgent = navigator.userAgent;
        const platform = navigator.platform;
        const language = navigator.language;

        // Дефолтна заглушка для локації
        let locationInfo = "Location not provided";

        // Отримання інформації про місцезнаходження (якщо дозволено)
        navigator.geolocation.getCurrentPosition(
          (position) => {
            locationInfo = `Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`;

            // Дані для відправки
            const templateParams = {
              name: this.form.name,
              email: this.form.email,
              phone: this.form.phone,
              case: this.form.case,
              userAgent: userAgent,
              platform: platform,
              language: language,
              location: locationInfo,
            };

            this.sendForm(templateParams);
          },
          (error) => {
            console.warn("Location access denied or error getting location:", error);

            // Якщо користувач відмовив у доступі до локації
            const templateParams = {
              name: this.form.name,
              email: this.form.email,
              phone: this.form.phone,
              case: this.form.case,
              userAgent: userAgent,
              platform: platform,
              language: language,
              location: locationInfo, // Заглушка для локації
            };

            this.sendForm(templateParams);
          }
        );
      } else {
        this.successMessage = "";
        this.errorMessage = "Please correct the errors in the form before submitting.";
      }
    },
    sendForm(templateParams) {
      emailjs.send("service_8cp3tya", "template_ulidzha", templateParams, "ziMoR2JSykXK6Lmmj")
        .then((response) => {
          console.log("Form submitted successfully:", response);
          this.form = { name: "", phone: "", email: "", case: "" }; // Очистити форму
          this.successMessage = "Your message has been sent successfully!";
          this.errorMessage = "";
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          this.successMessage = "";
          this.errorMessage = "There was an error sending your message. Please try again.";
        });
    },
    validateForm() {
      let valid = true;

      if (!this.form.name) {
        this.errors.name = "Name is required";
        valid = false;
      }
      if (!this.validateEmail(this.form.email)) {
        this.errors.email = "Please enter a valid email address";
        valid = false;
      }
      if (!this.validatePhone(this.form.phone)) {
        this.errors.phone = "Please enter a valid phone number";
        valid = false;
      }
      if (!this.form.case) {
        this.errors.case = "Message is required";
        valid = false;
      }

      return valid;
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    validatePhone(phone) {
      const re = /^(?:\+?(\d{1,3}))?[\s.-]?(?:\(?(\d{1,5})\)?[\s.-]?)?(\d{1,5})[\s.-]?(\d{1,9})$/;
      return re.test(phone) && phone.replace(/\D/g, "").length > 7;
    },
  },
};
</script>

<style scoped>
/* Notification styles */
.notification {
  padding: 1rem;
  border-radius: 4px;
  margin-top: 1rem;
}

.notification.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.notification.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Form field styles */
.form-group {
  margin-bottom: 1rem;
}

input, textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input.is-invalid, textarea.is-invalid {
  border-color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
</style>
